import React from 'react';
import { Link } from 'react-router-dom';
import PostPageContent from '../components/posts/PostPageContent';
import { gql, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { PostContext } from '../services/PostsContextProvider';
import { useContext } from 'react';
import './postPage.scss';
import GooglePlusIcon from '../assets/icons/google-plus-svgrepo-com.svg';
import { FacebookIcon, LinkedinIcon, TwitterIcon, PinterestIcon, RedditIcon, WhatsappIcon } from 'react-share';
import PostCard from '../components/posts/PostPageContent';
import WebIcon from '../assets/icons/code-optimization-svgrepo-com.svg';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
const formatDate = (date) => new Date(date).toLocaleDateString();

export default function PostPage(props) {
	const location = useLocation();
	const { postData, isLoading, error } = useContext(PostContext);
	console.log(postData);
	const newData =
		postData &&
		postData.find((el) => {
			console.log(el.slug);

			return el.slug === location.pathname.slice(6);
		});

	const filteredData =
		postData &&
		postData.filter((el) => {
			console.log(el.slug);

			return el.slug !== location.pathname.slice(6);
		});
	console.log(filteredData);
	return (
		<div className="page-container">
			{isLoading ? (
				<p>Loading…</p>
			) : error ? (
				<p>Error: {error.message}</p>
			) : !newData ? (
				<p>Post could not be found.</p>
			) : (
				<div className="container">
					<div className="main">
						<PostPageContent post={newData} />

						<div className="share-buttons">
							<p className="share-text">Share on :</p>
							<FacebookShareButton url={window.location.href}>
								<FacebookIcon size={30} round={true} />
							</FacebookShareButton>
							<LinkedinShareButton url={window.location.href}>
								<LinkedinIcon size={30} round={true} />
							</LinkedinShareButton>
							<TwitterShareButton url={window.location.href}>
								<TwitterIcon size={30} round={true} />
							</TwitterShareButton>
							<WhatsappShareButton url={window.location.href}>
								<WhatsappIcon size={30} round={true} />
							</WhatsappShareButton>
						</div>
					</div>
					<div className="side-bar">
						<div className="side-container">
							<div className="side-banner">
								<h2>Web Development</h2>
								<img src={WebIcon} alt="web development icon" />
								<p>Call : 011 967 0094</p>
							</div>
							<h2 className="heading">Latest</h2>
							{filteredData &&
								filteredData.slice(0, 10).map((item) => {
									return (
										<Link to={`/blog/${item.slug}`}>
											<div className="item">
												<img src={item.featuredImage.node.sourceUrl} />
												<div className="title-container">{item.title}</div>
											</div>
										</Link>
									);
								})}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
