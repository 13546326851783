import React from 'react';
import Header from '../components/Header/Header';
import Process from '../components/Process/Process';
import Footprint from '../components/Footprint/Footprint';
import Industries from '../components/Industries/Industries';
import FirstBanner from '../components/First-banner/FirstBanner';
import SecondBanner from '../components/Second-banner/SecondBanner';
import Faqs from '../components/Faq/Faqs';
import { useContext } from 'react';
import { PostContext } from '../services/PostsContextProvider';
import { Helmet } from 'react-helmet';

const HomePage = () => {
	const { postData } = useContext(PostContext);

	return (
		<div>
			<Helmet>
				<script
					type="text/javascript"
					src="https://platform-api.sharethis.com/js/sharethis.js#property=64087edaa0932a001aed0dcd&product=inline-share-buttons&source=platform"
					async="async"
				/>
				<meta charset="utf-8" />

				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content="#000000" />
				<link rel="apple-touch-icon" href="https://web-okes.com/logo192.png" />
				<meta
					name="description"
					content="Web Development Experts Crafting High-performance & SEO Friendly Websites"
				/>
				<meta name="keywords" content="web design,web designer , mobile app development ,website" />

				<link rel="icon" href="./favicon.ico" />
				<title>Web Okes - Web Development</title>
				<meta property="og:title" content="Web Okes" />
				<meta property="og:url" content="https://www.web-okes.com" />
				<meta property="og:type" content="website" />
				<meta
					property="og:description"
					content="Web Development Experts Crafting High-performance & SEO Friendly Websites."
				/>
				<meta
					property="og:image"
					content="https://web-okes.com/static/media/web-okes-hero-background-image.42f6a7b74700fca46015.webpá"
				/>
			</Helmet>
			<Header />
			<Process />
			<FirstBanner />
			<Footprint />
			<Industries />
			<Faqs />
			<SecondBanner />
		</div>
	);
};

export default HomePage;
