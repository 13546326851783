import React from 'react';
import './SecondBanner.scss';
const SecondBanner = () => {
	return (
		<section className="second-banner">
			<div className="container">
				<h2>"Design is more than what it looks like and feels like. Design is how it works."</h2>
			</div>
		</section>
	);
};

export default SecondBanner;
