import React from 'react';
const StepSix = ({ formData, setFormData, page }) => {
	return (
		<div className={`step-six ${page === 5 ? 'visible' : 'hidden'}`}>
			<p> Your email address :</p>
			<input
				name="email"
				placeholder="Type your answer here..."
				type="email"
				value={formData.email}
				onChange={(event) => setFormData({ ...formData, email: event.target.value })}
			/>
		</div>
	);
};

export default StepSix;
