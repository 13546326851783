import React from 'react';
import { gql, useQuery } from '@apollo/client';
import PostCard from './PostCard';
import { PostContext } from '../../services/PostsContextProvider';
import { useContext } from 'react';
import './postList.scss';
// const GET_ALL_POSTS = gql`
// 	query getAllPosts {
// 		posts {
// 			nodes {
// 				author {
// 					node {
// 						avatar {
// 							url
// 						}
// 						name
// 					}
// 				}
// 				featuredImage {
// 					node {
// 						altText
// 						sourceUrl(size: LARGE)
// 					}
// 				}
// 				title
// 				content
// 				date
// 				seo {
// 					metaDesc
// 					opengraphAuthor
// 					opengraphDescription
// 					opengraphImage {
// 						sourceUrl
// 					}
// 				}
// 				slug
// 			}
// 		}
// 	}
// `;

export default function PostsList() {
	// const { loading, error, data } = useQuery(GET_ALL_POSTS);

	const { postData, isLoading, error } = useContext(PostContext);

	if (isLoading) return <p>Loading posts…</p>;
	if (error) return <p>Error : {error} </p>;

	if (!postData) {
		return <p>No matching posts found.</p>;
	}

	return (
		<div className="posts-list">{postData && postData.map((post) => <PostCard key={post.id} post={post} />)}</div>
	);
}
