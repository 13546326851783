import React from 'react';
import './ContactForm.scss';

const stepOne = ({ page }) => {
	return (
		<div className={`step-one ${page === 0 ? 'visible' : 'hidden'}`}>
			<h2>Holla! 👋 Glad You made it </h2>
			<p>
				Since each person's needs and opportunities are different ,we've created this form to help us make sure
				we're connecting you with the appropriate team.
			</p>
		</div>
	);
};

export default stepOne;
