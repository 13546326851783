import React from 'react';
import MapImage from '../../assets/images/south-african-map.gif';
import './Footprint.scss';
import { Fade } from 'react-reveal';

const Footprint = () => {
	return (
		<section className="footprint">
			<div className="container">
				<Fade bottom cascade damping={0.3}>
					<div className="left">
						<h1>Nation Wide footprint</h1>
						<p>
							People don't just stumble into our office—it is through a conscious, concerted effort to
							find the best web developers
						</p>
					</div>
				</Fade>
				<div className="right">
					<Fade duration={1500}>
						<img src={MapImage} alt="south african map with map marker on web okes locations" />
					</Fade>
					<div className="social-proof">
						<div className="item">
							<p>7+ years in the industry</p>
						</div>
						<div className="item">
							<p>9 Provinces</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Footprint;
