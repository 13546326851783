import React from 'react';

import WhatsappIcon from '../../assets/icons/whatsapp-white-icon.svg';
import './ContactButton.scss';

const ContactButton = ({ setOpenContactForm, openContactForm }) => {
	return (
		<button className="contact-button" onClick={() => window.open('https://wa.me/27796843417', '_blank')}>
			<img src={WhatsappIcon} alt="comment icon" />
		</button>
	);
};

export default ContactButton;
