import React from 'react';
import { Link } from 'react-router-dom';
import './postPageContent.scss';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
const formatDate = (date) => new Date(date).toLocaleDateString();

export default function PostCard({ post }) {
	const { title, date, seo, slug, featuredImage, content, author } = post;
	console.log(seo);

	return (
		<div className="post-page">
			<Helmet>
				<meta charSet="utf-8" />
				<title>{title}</title>
				<meta property="og:title" content={title} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="article" />
				<meta property="og:description" content={seo.metaDesc} />
				<meta property="og:image" content={featuredImage.node.sourceUrl} />
				<meta name="description" content={seo.metaDesc} />
			</Helmet>
			<div className="post">
				<div className="post-header">
					{featuredImage ? <img src={featuredImage.node.sourceUrl} alt={featuredImage.node.altText} /> : null}
				</div>
				<div>
					<h2>{title}</h2>
				</div>
				<div className="author-date">
					<div className="post-author">
						<img src={author.node.avatar.url} />
						<p>{author.node.name}</p>
					</div>
					<p>{formatDate(date)}</p>
				</div>
				<div className="content">{parse(content)}</div>
			</div>
		</div>
	);
}
