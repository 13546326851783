import React from 'react';
import { Link } from 'react-router-dom';
import './postCard.scss';
import parse from 'html-react-parser';

const formatDate = (date) => new Date(date).toLocaleDateString();

export default function PostCard({ post }) {
	const { title, date, slug, featuredImage, content, author } = post;
	console.log(post.author.node.avatar.url);

	return (
		<div className="post-card">
			<Link to={`/blog/${slug}`}>
				<div className="featured-image">
					{featuredImage ? <img src={featuredImage.node.sourceUrl} alt={featuredImage.node.altText} /> : null}
				</div>
				<div className="title-container">
					<div className="author-image">
						{post.author.node.avatar.url ? <img src={author.node.avatar.url} alt /> : null}
					</div>
					<div className="title">
						<h4>{title}</h4>
						<div className="name-date-container">
							<span className="name">{post.author.node.name}</span>
							<span>{formatDate(date)}</span>
						</div>
					</div>
				</div>
				<div className="desc">{parse(content + '...')}</div>
			</Link>
		</div>
	);
}
