import React from 'react';
import PrivacyPolicy from '../components/Privacy-policy/PrivacyPolicy';

const PrivacyPolicypage = () => {
	return (
		<div>
			<PrivacyPolicy />
		</div>
	);
};

export default PrivacyPolicypage;
