import React from 'react';
import './Process.scss';
import { process } from './process-data';
import { Link } from 'react-scroll';
import StickyBox from 'react-sticky-box';

import Fade from 'react-reveal/Fade';

const Process = () => {
	return (
		<section className="process" id="our-process">
			<div className="container">
				<div className="left">
					<StickyBox offsetTop={20} offsetBottom={20} className="sticky">
						<ul>
							{process.map((item) => {
								return (
									<li>
										<Link
											className="desktop-menu"
											activeClass="active"
											key={item.id}
											to={item.link}
											spy={true}
											smooth={true}
											offset={-300}
											duration={1000}
										>
											{item.title}
										</Link>
									</li>
								);
							})}
						</ul>
					</StickyBox>
				</div>
				<div className="right">
					{process.map((i) => {
						return (
							<section id={i.link}>
								<Fade bottom cascade damping={0.3}>
									<div className="item">
										<h2>{i.title}</h2>
										<p>{i.para}</p>
									</div>
								</Fade>
							</section>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default Process;
