import React from 'react';
import Faq from 'react-faq-component';
import './Faqs.scss';
import { data, data2, config } from './data.js';
import { Fade } from 'react-reveal';

const Faqs = () => {
	return (
		<div className="faq" id="faq">
			<div className="container">
				<Fade top delay={500}>
					<div className="heading">
						<h2>FREQUENTLY ASKED QUESTIONS</h2>
						<p>
							Here are a few questions we often get asked about our experience and our We’d be happy to
							clear up any questions you might
						</p>
					</div>
				</Fade>
				<Fade bottom damping={0.3}>
					<div className="content">
						<div className="left">
							<Faq data={data} config={config} className="faq" />
						</div>
						<div className="right">
							<Faq data={data2} config={config} className="faq" />
						</div>
					</div>
				</Fade>
			</div>
		</div>
	);
};

export default Faqs;
