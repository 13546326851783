import React from 'react';

const stepTwo = ({ formData, setFormData, page }) => {
	return (
		<div className={`step-two ${page === 1 ? 'visible' : 'hidden'}`}>
			<p>Let's give your message a subject line before we begin!</p>
			<input
				name="subject"
				type="text"
				placeholder="Type your answer here..."
				value={formData.subject}
				onChange={(event) => setFormData({ ...formData, subject: event.target.value })}
			/>
		</div>
	);
};

export default stepTwo;
