import React from 'react';

const stepFour = ({ formData, setFormData, page }) => {
	return (
		<div className={`step-four ${page === 3 ? 'visible' : 'hidden'}`}>
			<p className="head">Awesome: Let us get to know you a little better.</p>
			<p> Please tell us your name :</p>
			<input
				name="name"
				type="text"
				value={formData.name}
				onChange={(event) => setFormData({ ...formData, name: event.target.value })}
				placeholder="Type your answer here..."
			/>
		</div>
	);
};

export default stepFour;
