import React from 'react';
import './Footer.scss';
import logo from '../../assets/images/web-okes-white-logo.svg';
import envelopeIcon from '../../assets/icons/Icon awesome-envelope.svg';
import phoneIcon from '../../assets/icons/Icon awesome-phone-alt.svg';
import whatsappIcon from '../../assets/icons/Icon awesome-whatsapp.svg';
import facebookIcon from '../../assets/icons/facebook-icon.svg';
import twitterIcon from '../../assets/icons/twitter-icon.svg';
import instagramIcon from '../../assets/icons/instagram-icon.svg';
import ticktokIcon from '../../assets/icons/ticktok-icon.svg';
import chevIcon from '../../assets/icons/chev-up.svg';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
const Footer = ({ setCookies, setPrivacy }) => {
	return (
		<section className="footer">
			<div className="container">
				<div className="business">
					<Link to="header" spy={true} smooth={true} offset={0} duration={1000}>
						<img src={logo} alt="Web okes logo" />
					</Link>
					<p>
						Successful web design takes into account more than just aesthetics… It’s a reflection of you,
						your company, and your vision.
					</p>
				</div>
				<div className="contact-details">
					<ul className="contact">
						<li>
							<img src={envelopeIcon} alt="envelope icon" /> info@web-okes.com
						</li>
						<li>
							<img src={phoneIcon} alt="phone icon" /> 011 967 0094
						</li>
						<li>
							<img src={whatsappIcon} alt="whatsapp icon" /> 079 684 3417
						</li>
					</ul>
					<ul className="social-media">
						<li>
							<img
								src={facebookIcon}
								alt="facebook icon"
								onClick={() =>
									window.open(
										'https://www.facebook.com/profile.php?id=100087655017834&mibextid=LQQJ4d',
										'_blank'
									)}
							/>
							<img
								src={twitterIcon}
								alt="twitter icon"
								onClick={() => window.open('https://twitter.com/Web_Okes', '_blank')}
							/>
							<img
								src={instagramIcon}
								alt="instagram icon"
								onClick={() =>
									window.open('https://instagram.com/web_okes?igshid=YmMyMTA2M2Y=', '_blank')}
							/>
							<img
								src={ticktokIcon}
								alt="ticktok icon"
								onClick={() =>
									window.open('https://www.tiktok.com/@web_okes?_t=8ZfbyYE6poj&_r=1', '_blank')}
							/>
						</li>
					</ul>
				</div>
				<div className="links">
					<ul>
						<li>
							<Link
								className="desktop-menu"
								activeClass="active"
								to="our-process"
								spy={true}
								smooth={true}
								offset={0}
								duration={1000}
							>
								Our Process
							</Link>
						</li>
						<li>
							<Link
								className="desktop-menu"
								activeClass="active"
								to="industries"
								spy={true}
								smooth={true}
								offset={-80}
								duration={1000}
							>
								Industries
							</Link>
						</li>

						<li>
							<Link
								className="desktop-menu"
								activeClass="active"
								to="faq"
								spy={true}
								smooth={true}
								offset={-80}
								duration={1000}
							>
								FAQ's
							</Link>
						</li>
						<li>
							{' '}
							<RouterLink to="blog">Blog</RouterLink>
						</li>
					</ul>
				</div>
				<div className="policy-links">
					<ul>
						<li>
							<RouterLink to="cookie-policy">Cookie Policy</RouterLink>{' '}
						</li>
						<li>
							<RouterLink to="privacy-policy">Privacy Policy </RouterLink>
						</li>
					</ul>
				</div>
			</div>
			<div className="copyright">
				<p>© Web Okes 2023</p>

				<Link to="header" spy={true} smooth={true} offset={0} duration={1000}>
					<img src={chevIcon} alt="back to top button" />
				</Link>
			</div>
		</section>
	);
};

export default Footer;
