import React from 'react';
import './Industries.scss';
import scaleIcon from '../../assets/icons/Icon awesome-balance-scale.svg';
import creditCardIcon from '../../assets/icons/Icon awesome-credit-card.svg';
import toolsIcon from '../../assets/icons/Icon awesome-tools.svg';
import bookIcon from '../../assets/icons/Icon awesome-book.svg';
import buildingIcon from '../../assets/icons/Icon awesome-building.svg';
import laptopIcon from '../../assets/icons/Icon awesome-laptop.svg';
import clinicIcon from '../../assets/icons/Icon awesome-clinic-medical.svg';
import roadIcon from '../../assets/icons/Icon awesome-road.svg';
import commentsIcon from '../../assets/icons/Icon awesome-comments.svg';
import shoppingCartIcon from '../../assets/icons/Icon awesome-shopping-cart.svg';
import { Fade } from 'react-reveal';
const Industries = () => {
	const data = [
		{
			id: 1,
			icon: scaleIcon,
			title: 'Legal'
		},
		{
			id: 2,
			icon: creditCardIcon,
			title: 'Fintech'
		},
		{
			id: 3,
			icon: toolsIcon,
			title: 'Engineering'
		},
		{
			id: 4,
			icon: bookIcon,
			title: 'Education'
		},
		{
			id: 5,
			icon: buildingIcon,
			title: 'Construction'
		},
		{
			id: 6,
			icon: laptopIcon,
			title: 'Technology'
		},
		{
			id: 7,
			icon: clinicIcon,
			title: 'Medical'
		},
		{
			id: 8,
			icon: roadIcon,
			title: 'Transportation'
		},
		{
			id: 9,
			icon: commentsIcon,
			title: 'Consulting'
		},
		{
			id: 10,
			icon: shoppingCartIcon,
			title: 'Construction'
		}
	];

	return (
		<section className="industries" id="industries">
			<div className="container">
				<Fade top delay={500}>
					<h2>Industries we specialize in</h2>
				</Fade>
				<ul>
					{data.map((item) => {
						return (
							<Fade bottom damping={0.3}>
								<li>
									<img src={item.icon} alt={`${item.title} icon`} />
									<p>{item.title}</p>
								</li>
							</Fade>
						);
					})}
				</ul>
			</div>
		</section>
	);
};

export default Industries;
