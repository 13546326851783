import './menu.scss';
import { Link } from 'react-scroll';
import { Link as RealLink } from 'react-router-dom';

const Menu = ({ menuOpen, setMenuOpen }) => {
	return (
		<div className={'menu ' + (menuOpen && 'active')}>
			<ul>
				<li>
					<Link
						className="desktop-menu"
						activeClass="active"
						to="our-process"
						spy={true}
						smooth={true}
						offset={-80}
						duration={1000}
						onClick={() => setMenuOpen(false)}
					>
						Our Process
					</Link>
				</li>
				<li>
					<Link
						className="desktop-menu"
						activeClass="active"
						to="industries"
						spy={true}
						smooth={true}
						offset={-80}
						duration={1000}
						onClick={() => setMenuOpen(false)}
					>
						Industries
					</Link>
				</li>

				<li>
					<Link
						className="desktop-menu"
						activeClass="active"
						to="faq"
						spy={true}
						smooth={true}
						offset={-80}
						duration={1000}
						onClick={() => setMenuOpen(false)}
					>
						FAQ's
					</Link>
				</li>
				<li>
					<RealLink
						className="desktop-menu"
						activeClass="active"
						onClick={() => setMenuOpen(false)}
						to="/blog"
					>
						Blog
					</RealLink>
				</li>
			</ul>
		</div>
	);
};

export default Menu;
