import React from 'react';
import Cookies from '../components/Cookies/Cookies';

const CookiePolicyPage = () => {
	return (
		<div>
			<Cookies />
		</div>
	);
};

export default CookiePolicyPage;
