import React from 'react';

const StepFive = ({ formData, setFormData, page }) => {
	return (
		<div className={`step-five ${page === 4 ? 'visible' : 'hidden'}`}>
			<p> Company Name : </p>
			<input
				name="company_name"
				placeholder="Type your answer here..."
				type="text"
				value={formData.companyName}
				onChange={(event) => setFormData({ ...formData, companyName: event.target.value })}
			/>
		</div>
	);
};

export default StepFive;
