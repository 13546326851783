import React from 'react';
import './ContactForm.scss';

const stepThree = ({ formData, setFormData, page }) => {
	return (
		<div className={`step-three ${page === 2 ? 'visible' : 'hidden'}`}>
			<p>How may we be of service to you?</p>
			<div className="boxes">
				<div className="group-container">
					<input
						type="checkbox"
						id="web-development"
						checked={formData.webDevelopment}
						onChange={(event) => setFormData({ ...formData, webDevelopment: event.target.checked })}
						name="web_development"
						value={formData.graphicsDesign ? 'Yes' : 'No'}
					/>
					<label for="web_development">Web Development</label>
				</div>
				<div className="group-container">
					<input
						type="checkbox"
						id="graphics-design"
						name="graphics_design"
						value={formData.graphicsDesign ? 'Yes' : 'No'}
						checked={formData.graphicsDesign}
						onChange={(event) => setFormData({ ...formData, graphicsDesign: event.target.checked })}
					/>
					<label for="graphics_design">Graphics Design</label>
				</div>
			</div>
		</div>
	);
};

export default stepThree;
