export const process = [
	{
		id: 1,
		offset: -100,
		title: 'Discovery',
		link: 'discovery',
		para:
			'We begin by assessing your current environment and technology stack, speaking to your key stakeholders and tech leads. Our goal is to understand your business needs and learn how these inform your technology requirements. Technical discovery is part of an integrated discovery and strategy process.'
	},
	{
		id: 2,
		offset: 0,
		title: 'Analysis',
		link: 'analysis',
		para:
			'Before we define any approach, we need to always understand and define the ultimate development stack that your project will need to achieve its overall goal. Our technology team provides a full analysis of site content, traffic, security requirements, and delivers a full description of proper platforms and APIs needed to achieve these goals within budget allocations.  '
	},
	{
		id: 3,
		offset: 0,
		title: 'Systems Architecture',
		link: 'systems',
		para:
			'In this stage, we focus on organizing the overall structure and behavior of all the components, APIs, servers, databases, distribution and security systems to gathered to a workable plan. From a front-end aspect, our technology team works closely with our UX team to ensure technical functionalities are defined, all pieces fit together perfectly;'
	},
	{
		id: 4,
		offset: 0,
		title: 'User Experience',
		link: 'user',
		para:
			'We’re an end-to-end website development company, so we are more than capable of handling your digital strategy, information architecture, website design, and content creation. We begin planning early for the features we know the site will need so that we can scope development appropriately And we architect and design the website with an understanding of the unique capabilities of the platform the site will be built on.'
	},
	{
		id: 5,
		offset: 0,
		title: 'Front and Back-End Development',
		link: 'front',
		para:
			'Our development teams are fluent in the whole range of modern web development languages, including PHP, .Net, Java, HTML5, CSS, and Javascript. With our range of partnerships, we have the expertise – including many certifications – for a wide variety of CMS options.'
	},
	{
		id: 6,
		offset: 0,
		title: 'Quality Assurance',
		link: 'quality',
		para:
			'Before we launch any project, we will assess how optimized it is, how easy to use it is for clients, whether its code is valid. We also ensure that it gives you value for your money and passes our high-quality assurance standards for all your development needs.'
	},
	{
		id: 7,
		offset: 100,
		title: 'Launch',
		link: 'launch',
		para:
			"If everything passes our rigorous quality assurance development process, we then proceed to launch. begin marketing plan execution and start analyzing its results to see its progress. And unlike other companies, we do not just leave you after launch. We proceed to monitor, analyze and report on your project's progress in order to ensure its performing at peak level."
	},
	{
		id: 8,
		offset: 100,
		title: 'Optimization',
		link: 'optimization',
		para:
			'After launch we stick around, continue to monitor your site for browser issues, speed tests and security issues. We want to collect data, test and optimize your new website to ensure it is working and converting. Ensuring your site is in tip-top shape and working hard for your business.'
	}
];
