import './FirstBanner.scss';

const FirstBanner = () => {
	return (
		<div className="first-banner">
			<div className="container">
				<h2>"Never settle for less than great web design."</h2>
			</div>
		</div>
	);
};

export default FirstBanner;
