import './App.scss';
import Navbar from '../src/components/Navbar/Navbar';
import Header from './components/Header/Header';
import Process from './components/Process/Process';
import Footprint from './components/Footprint/Footprint';
import Industries from './components/Industries/Industries';
import Faqs from './components/Faq/Faqs';
import SecondBanner from './components/Second-banner/SecondBanner';
import Footer from './components/Footer/Footer';
import ContactButton from './components/Contact-button/ContactButton';
import ContactForm from './components/Contact-form/ContactForm';
import { motion, AnimatePresence } from 'framer-motion';
import CookieConsent from 'react-cookie-consent';
import { useState, useEffect, useLayoutEffect } from 'react';
import Menu from './components/menu/Menu';
import PrivacyPolicy from './components/Privacy-policy/PrivacyPolicy';
import Cookies from './components/Cookies/Cookies';
import FirstBanner from './components/First-banner/FirstBanner';
import Loader from './components/Loader/Loader';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import PrivacyPolicypage from './pages/PrivacyPolicypage';
import { PostsContextProvider } from './services/PostsContextProvider';
import PostPageContent from './components/posts/PostPageContent';
import { ApolloProvider } from '@apollo/client/react';
import client from './components/lib/apollo';
import PostPage from './pages/PostPage';

function App() {
	const location = useLocation();
	const [ openContactForm, setOpenContactForm ] = useState(false);
	const [ menuOpen, setMenuOpen ] = useState(false);
	const [ openCookies, setCookies ] = useState(false);
	const [ openPrivacy, setPrivacy ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const modal = {
		hidden: {
			opacity: 0,
			transition: { duration: 0.5, ease: 'easeInOut' }
		},
		visible: {
			opacity: 1,
			transition: { duration: 0.5, ease: 'easeInOut' }
		},
		exit: {
			opacity: 0,
			transition: { duration: 0.5, ease: 'easeInOut' }
		}
	};
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, 2000);
	}, []);
	const Wrapper = ({ children }) => {
		const location = useLocation();
		useLayoutEffect(
			() => {
				document.documentElement.scrollTo(0, 0);
			},
			[ location.pathname ]
		);
		return children;
	};
	return (
		<ApolloProvider client={client}>
			<div className="App">
				<PostsContextProvider>
					<CookieConsent
						location="bottom"
						buttonText="Accept"
						cookieName="myAwesomeCookieName2"
						containerClasses="item-cookie"
						expires={365}
					>
						<div style={{ textAlign: 'center' }}>
							<h3>We value your privacy</h3>
							<br />We use cookies to enhance your browsing experience and analyze our traffic.<br />By
							clicking "Accept", you consent to our use of cookies <span> Cookie Policy</span>{' '}
						</div>
					</CookieConsent>
					<Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
					<Navbar
						openContactForm={openContactForm}
						setOpenContactForm={setOpenContactForm}
						menuOpen={menuOpen}
						setMenuOpen={setMenuOpen}
					/>
					<ContactButton openContactForm={openContactForm} setOpenContactForm={setOpenContactForm} />
					<Wrapper>
						<Routes location={location} key={location.pathname}>
							<Route path="/" element={<HomePage />} />
							<Route path="/blog" element={<BlogPage />} />
							<Route path="/blog/:slug" element={<PostPage />} />
							<Route path="/cookie-policy" element={<CookiePolicyPage />} />
							<Route path="/privacy-policy" element={<PrivacyPolicypage />} />
						</Routes>
					</Wrapper>
					<AnimatePresence mode="wait">
						{openContactForm && (
							<motion.div
								className={`contact-form ${openContactForm ? 'open' : 'close'}`}
								variants={modal}
								animate="visible"
								initial="hidden"
								exit="exit"
							>
								<ContactForm
									openContactForm={openContactForm}
									setOpenContactForm={setOpenContactForm}
								/>
							</motion.div>
						)}
					</AnimatePresence>
					<Footer />
				</PostsContextProvider>

				{/* {loading ? (
				<div>
					<Loader />
				</div>
			) : (
				<div className="App">
					<CookieConsent
						location="bottom"
						buttonText="Accept"
						cookieName="myAwesomeCookieName2"
						containerClasses="item-cookie"
						expires={365}
					>
						<div style={{ textAlign: 'center' }}>
							<h3>We value your privacy</h3>
							<br />We use cookies to enhance your browsing experience and analyze our traffic.<br />By
							clicking "Accept", you consent to our use of cookies <span> Cookie Policy</span>{' '}
						</div>
					</CookieConsent>
					<Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
					<Navbar
						openContactForm={openContactForm}
						setOpenContactForm={setOpenContactForm}
						menuOpen={menuOpen}
						setMenuOpen={setMenuOpen}
					/>
					<ContactButton openContactForm={openContactForm} setOpenContactForm={setOpenContactForm} />
					<Header openContactForm={openContactForm} setOpenContactForm={setOpenContactForm} />
					<Process />
					<FirstBanner />
					<Footprint />
					<Industries />
					<Faqs />
					<SecondBanner />
					<Footer
						openCookies={openCookies}
						setCookies={setCookies}
						openPrivacy={openPrivacy}
						setPrivacy={setPrivacy}
					/>
					<AnimatePresence mode="wait">
						{openContactForm && (
							<motion.div
								className={`contact-form ${openContactForm ? 'open' : 'close'}`}
								variants={modal}
								animate="visible"
								initial="hidden"
								exit="exit"
							>
								<ContactForm
									openContactForm={openContactForm}
									setOpenContactForm={setOpenContactForm}
								/>
							</motion.div>
						)}
					</AnimatePresence>
					{openPrivacy && <PrivacyPolicy openPrivacy={openPrivacy} setPrivacy={setPrivacy} />}
					{openCookies && <Cookies openCookies={openCookies} setCookies={setCookies} />}
				</div>
			)} */}
			</div>
		</ApolloProvider>
	);
}

export default App;
