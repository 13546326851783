import React, { useState, useRef } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepSix from './StepSix';
import './ContactForm.scss';
import emailjs from '@emailjs/browser';

import StepFive from './StepFive';
import StepSeven from './StepSeven';

const ContactForm = ({ setOpenContactForm, openContactForm }) => {
	const form = useRef();
	const [ page, setPage ] = useState(0);
	const [ sent, setSent ] = useState(false);
	const [ formData, setFormData ] = useState({
		subject: '',
		webDevelopment: '',
		graphicsDesign: '',
		name: '',
		companyName: '',
		email: '',
		phoneNumber: ''
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_0774yjj', 'template_awo0nu2', form.current, 'b4Ddk0W5AiJG-JtXw').then(
			(result) => {
				setSent(true);
			},
			(error) => {}
		);
	};

	return (
		<section>
			<div className="progress-bar">
				<div style={{ width: `${100 / 7 * (page + 1)}%` }} />
			</div>
			<span className="close-button" onClick={() => setOpenContactForm(false)}>
				x
			</span>
			<div className="container">
				<h1>Contact Us</h1>
				<form ref={form} onSubmit={handleSubmit} className="form-container">
					{sent ? (
						<div className="success-message">
							<h2>Thanks for being awesome! </h2>
							<p>We have received your message and would like to thank you for writing to us.</p>
							<p>One of our professionals will be in-touch </p>
							<p>Talk to you soon </p>
						</div>
					) : (
						<div className="form-body">
							<StepOne page={page} />
							<StepTwo page={page} formData={formData} setFormData={setFormData} />
							<StepThree page={page} formData={formData} setFormData={setFormData} />
							<StepFour page={page} formData={formData} setFormData={setFormData} />
							<StepFive page={page} formData={formData} setFormData={setFormData} />
							<StepSix page={page} formData={formData} setFormData={setFormData} />
							<StepSeven page={page} formData={formData} setFormData={setFormData} />
						</div>
					)}
					{sent === false && (
						<div className="form-footer">
							{page === 0 ? (
								<div className="buttons">
									<div className="form-btn" onClick={() => setPage((currpage) => currpage + 1)}>
										Let’s Start
									</div>
								</div>
							) : (
								<div className="buttons">
									<div className="form-btn" onClick={() => setPage((currpage) => currpage - 1)}>
										Previous
									</div>
									{page === 6 ? (
										<button className="sub-button" type="submit">
											submit
										</button>
									) : (
										<div className="form-btn" onClick={() => setPage((currpage) => currpage + 1)}>
											Next
										</div>
									)}
								</div>
							)}

							{page === 0 || (sent === false && <p className="message">It Won’t take Long</p>)}
						</div>
					)}
				</form>
			</div>
		</section>
	);
};

export default ContactForm;
