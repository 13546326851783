import React from 'react';
import PostList from '../components/posts/PostList';

const BlogPage = () => {
	return (
		<div className="container">
			<PostList />
		</div>
	);
};

export default BlogPage;
