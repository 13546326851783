import React, { createContext, useState, useEffect } from 'react';
import { request, gql } from 'graphql-request';

export const PostContext = createContext();

export const PostsContextProvider = ({ children }) => {
	const [ postData, setPostData ] = useState();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ error, setError ] = useState(null);

	const query = gql`
		{
			posts {
				nodes {
					author {
						node {
							avatar {
								url
							}
							name
						}
					}
					content
					date
					slug
					featuredImage {
						node {
							sourceUrl(size: LARGE)
							altText
						}
					}
					title
					seo {
						title
						metaDesc
					}
				}
			}
		}
	`;

	useEffect(
		() => {
			setIsLoading(true);
			request('https://www.web-okes.com/my-blog/graphql', query)
				.then((data) => {
					setPostData(data.posts.nodes);
					setIsLoading(false);
				})
				.catch((err) => {
					setError(err);
					setIsLoading(false);
				});
		},
		[ query ]
	);

	return (
		<PostContext.Provider
			value={{
				postData,
				isLoading,
				error
			}}
		>
			{children}
		</PostContext.Provider>
	);
};
