import React, { useState } from 'react';
import RedLogo from '../../assets/images/web-okes-red-logo.svg';
import WhiteLogo from '../../assets/images/web-okes-white-logo.svg';
import CustomButton from '../Custom-Button/CustomButton';
import { Link } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { Link as RealLink } from 'react-router-dom';
import './Navbar.scss';
import AngleleftIcon from '../../assets/icons/angle-left.svg';

const Navbar = ({ setOpenContactForm, openContactForm, menuOpen, setMenuOpen }) => {
	const [ scrolled, setScrolled ] = useState(false);
	const location = useLocation();

	const changeBackground = () => {
		if (window.window.scrollY >= 80) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	window.addEventListener('scroll', changeBackground);

	return (
		<nav
			className={`navigation ${scrolled || location.pathname !== '/' ? 'scrolled' : ''} ${menuOpen && 'active'}`}
		>
			<div className="container">
				<div className="left">
					<Link to="our-process" spy={true} smooth={true} offset={-500} duration={1000}>
						<img
							src={scrolled || location.pathname !== '/' || window.innerWidth < 768 ? RedLogo : WhiteLogo}
							alt="Web Okes White Logo"
						/>
					</Link>
				</div>
				{location.pathname === '/' && (
					<div className="middle">
						<ul>
							<li>
								<Link
									className="desktop-menu"
									activeClass="active"
									to="our-process"
									spy={true}
									smooth={true}
									offset={0}
									duration={1000}
								>
									Our Process
								</Link>
							</li>
							<li>
								<Link
									className="desktop-menu"
									activeClass="active"
									to="industries"
									spy={true}
									smooth={true}
									offset={-80}
									duration={1000}
								>
									Industries
								</Link>
							</li>

							<li>
								<Link
									className="desktop-menu"
									activeClass="active"
									to="faq"
									spy={true}
									smooth={true}
									offset={-80}
									duration={1000}
								>
									FAQ's
								</Link>
							</li>
							<li>
								<RealLink className="desktop-menu" to="/blog">
									Blog
								</RealLink>
							</li>
						</ul>
					</div>
				)}
				<div className="right">
					{location.pathname === '/' && (
						<div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
							<span className="line1" />
							<span className="line2" />
							<span className="line3" />
						</div>
					)}

					{location.pathname === '/' ? (
						<div onClick={() => setOpenContactForm(!openContactForm)} className="btn">
							<CustomButton>Contact Us</CustomButton>
						</div>
					) : (
						<div className="btn blog">
							<RealLink to="/">
								<CustomButton>
									<img src={AngleleftIcon} alt="left icon" /> Go Home
								</CustomButton>
							</RealLink>
						</div>
					)}
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
