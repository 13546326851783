import React from 'react';
import './Header.scss';
import { Typewriter } from 'react-simple-typewriter';
import RightIcon from '../../assets/icons/Icon awesome-angle-right.svg';

const Header = ({ setOpenContactForm }) => {
	return (
		<section className="header" id="header">
			<div className="container">
				<div className="left">
					<h1>Web Development Experts Crafting High-performance & SEO Friendly Websites</h1>
					<hr />
					<div className="heading">
						<Typewriter
							words={[ 'Transforming Brands Through Research Driven Web Development.' ]}
							cursor
							cursorStyle="|"
							typeSpeed={70}
							deleteSpeed={50}
							delaySpeed={1000}
							cursorBlinking={false}
						/>
					</div>

					<div>
						<p className="btn" onClick={() => setOpenContactForm(true)}>
							Get Started <img src={RightIcon} alt="right icon" />
						</p>
					</div>
				</div>

				<div className="right" />
			</div>
		</section>
	);
};

export default Header;
