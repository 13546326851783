import React from 'react';
import './ContactForm.scss';

const StepSeven = ({ page, formData, setFormData }) => {
	return (
		<div className={`step-seven ${page === 6 ? 'visible' : 'hidden'}`}>
			<p> Your Phone Number :</p>
			<input
				name="phoneNumber"
				placeholder="Type your answer here..."
				type="number"
				value={formData.phoneNumber}
				onChange={(event) => setFormData({ ...formData, phoneNumber: event.target.value })}
				required
			/>
		</div>
	);
};

export default StepSeven;
