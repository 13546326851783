export const data = {
	rows: [
		{
			title: 'What software languages can you develop with?',
			content:
				'Our team is blessed with industry experts in PHP, Python, HTML5, CSS, JavaScript, Word Press to name a few.'
		},
		{
			title: 'Can you make my application/website work with mobile devices?',
			content:
				'Absolutely. Our system designs are done with responsive methods in mind.This means that your system will adjust to each users display & look great no matter if viewed on mobile, desktop or any monitor size.'
		},
		{
			title: 'What if I don’t like the website?',
			content:
				'You’re in luck. We offer a risk-free guarantee. Before we build your new website, we’ll design a mockup of your homepage. We’ll design a layout in Photoshop first, that way you’ll get to see our initial designs within approximately five days. This is your opportunity to give us feedback and if you really don’t like it, you don’t have to move forward. Best part is, this won’t cost you anything. '
		},
		{
			title: 'Do you offer a payment schedule?',
			content:
				'Yes, we split the payment into two. The first 50% is usually taken once you have seen the mockup of your homepage and you’re happy to move forward. The following 50% is taken 30 days after this. '
		}
	]
};

export const data2 = {
	rows: [
		{
			title: 'What if I don’t want to manage the website at all?',
			content:
				'Yes, we can arrange a maintenance package to suit your needs. This can range from 1hr per month to 10 hours per month and we can discuss a package that’s right for you.'
		},
		{
			title: 'Can I update the website myself once it’s been built?',
			content:
				'Yes. We like to offer the ability for our clients to update the website themselves. We we’ll give you all the training and tools to be able to make website amendments. We use a easy to use platform called WordPress so you can add edit and delete content without paying us to do it for you. '
		},
		{
			title: 'I already have a website, how easy is it to change it?',
			content:
				'If you already have a website. That’s great. This means we can build your new website whilst your other website is still live. Once we are ready to go live and you are happy with the new site we can then swap the old site for the new one. It can take as little as 10 minutes for the new website to be visible. This means you’ll barely even notice a dip in your traffic numbers. '
		},
		{
			title: 'Do you do SEO for my website when you build it?',
			content:
				'It depends which package you choose. SEO is usually an ongoing commitment and will require continuous work. However, we do offer a package that will start you off on the right foot. '
		}
	]
};

export const config = {
	animate: true,
	expandIcon: '+',
	collapseIcon: '-'
};
